<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('institutionEbay.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
    </v-toolbar>

    <!---->
    <div class="col-12">
      <v-layout>
        <v-flex xs12>
          <v-card flat>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                lg12
              >
                <v-container grid-list-md>
                  <!-- PART DESCRIPTION -->
                  <v-toolbar
                    flat
                    dark
                  >
                    <v-toolbar-title>
                      {{ $t('leko_employees.ebay_shipping_price.part_description') }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-layout
                    mb-2
                    wrap
                  >
                    <v-flex
                      xs12
                      sm4
                      md4
                      class="mt-4"
                    >
                      <label class="ml-2">{{ $t('parts.inventory.part_title') }}</label>
                      <v-autocomplete
                        v-model="title"
                        :loading="loadingTitle"
                        :items="titles"
                        :search-input.sync="searchTitle"
                        :label="$t('parts.inventory.search')"
                        :filter="() => true"
                        :item-text="`title.${$store.getters.language}`"
                        solo
                        color="red darken-1"
                        append-icon="search"
                        class="mt-2"
                        single-line
                        hide-details
                        hide-no-data
                        return-object
                      />
                    </v-flex>

                    <v-flex
                      xs12
                      sm3
                      md3
                      class="mt-4"
                    >
                      <label class="ml-2">{{ $t('institutionEbay.marketplace') }}</label>
                      <v-autocomplete
                        v-model="marketplace"
                        :items="markets"
                        :label="$t('institutionEbay.marketplace')"
                        persistent-hint
                        item-text="title"
                        item-value="value"
                        solo
                        color="red darken-1"
                        class="ml-2 mr-2 mt-2"
                        single-line
                        hide-details
                        hide-no-data
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      md2
                      class="mt-4"
                    >
                      <label class="ml-2">{{ $t('institutionEbay.country') }}</label>

                      <v-autocomplete
                        v-model="country"
                        :items="countries"
                        :label="$t('institutionEbay.country')"
                        persistent-hint
                        item-text="title"
                        item-value="code"
                        solo
                        color="red darken-1"
                        class="ml-2 mr-2 mt-2"
                      />
                    </v-flex>

                    <v-flex
                      xs12
                      sm5
                      md3
                      class="mt-4"
                    >
                      <label class="">{{ $t('institutionEbay.update_shipping_price') }}</label>

                      <v-text-field
                        v-model="price"
                        type="number"
                        oninput="validity.valid||(value='');"
                        :label="$t('institutionEbay.update_shipping_price')"
                        color="red darken-1"
                        solo
                        class="mt-2"
                      />
                    </v-flex>
                    <v-flex
                      v-if="$store.getters.role === 'SuperAdmin'"
                      xs12
                      sm6
                    >
                      <label
                        class="ml-2"
                      >{{ $t('parts.advanced_search.institution') }}</label>
                      <v-autocomplete
                        v-model="institution"
                        :items="displayedInstitutions"
                        :loading="loadingInstitution"
                        :label="$t('parts.advanced_search.institution')"
                        persistent-hint
                        item-text="title"
                        item-value="_id"
                        solo
                        color="red darken-1"
                        class="ml-2 mr-2  pt-2"
                      />
                    </v-flex>
                    <v-flex
                      v-else
                      xs12
                      sm6
                    />

                    <v-flex
                      xs12
                      sm6
                      class="ml-auto mt-4"
                      d-flex
                    >
                      <v-btn
                        :loading="loadingAssignInstitutionPrice"
                        :disabled="!(institution && title && marketplace && country && price)"
                        color="red darken-1"
                        class="white--text text-style"
                        @click="assignInstitutionPrice"
                      >
                        {{ $t('leko_employees.ebay_shipping_price.assign_shipping_reference_institution') }}
                      </v-btn>
                    </v-flex>
                    <v-flex

                      xs12
                      lg6
                    >
                      <v-container
                        v-if="titleDetails"
                      >
                        <v-card class="pa-3 text-start">
                          <div class="mb-2">
                            <h3>
                              {{ $t('leko_employees.ebay_shipping_price.title_details') }}
                            </h3>
                          </div>
                          <div
                            v-if="$store.getters.role === 'SuperAdmin'"
                            class="text-start mb-1 col-12 "
                          >
                            <strong class="pt-1">partCode: </strong>
                            <a>{{ titleDetails.partCode }}</a>
                          </div>
                          <div class="text-start mb-1 col-12 ">
                            <strong class="pt-1">{{ $t('institutionEbay.weight_range') }} </strong>
                            <a>{{ titleDetails.weightRange }} kg</a>
                          </div>
                          <div class="text-start mb-1">
                            <strong>{{ $t('institutionEbay.label') }}: </strong>

                            <div
                              v-for="(platformCosts, platform) in titleDetails.shippingCosts"
                              :key="platform"
                            >
                              <h3>{{ platform.charAt(0).toUpperCase() + platform.slice(1) }}</h3>

                              <ul>
                                <li
                                  v-for="(cost, country) in platformCosts"
                                  :key="country"
                                >
                                  {{ country.toUpperCase() }}: €{{ cost }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </v-card>
                      </v-container>
                    </v-flex>
                    <!-- <v-flex
                      xs12
                      sm6
                      class="ml-auto"
                      d-flex
                    >
                      <v-btn
                        :loading="loadingAssignPartPrice"
                        :disabled="!(institution && title && marketplace && country && price)"
                        color="red darken-1"
                        class="white--text"
                        @click="assignPartPrice"
                      >
                        <img
                          height="24"
                          width="24"
                          class="mr-2"
                          src="@/assets/icons/insert_parts.svg"
                        >  {{ $t('leko_employees.ebay_shipping_price.assign_shipping_cost_part') }}
                      </v-btn>
                    </v-flex> -->
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>
            <v-flex
              xs12
              sm12
              class="pl-3 pr-3"
            >
              <v-card class="pa-3 ">
                <v-alert
                  :value="true"
                  type="info"
                  border="bottom"
                  outline
                  prominent
                  text
                >
                  <div class="col-12 display">
                    <div class="pt-2 col-4">
                      <strong>📌 {{ $t('institutionEbay.formula') }}:</strong>
                      ({{ $t('institutionEbay.part_price') }} + {{ $t('institutionEbay.shipping') }}) * 12,5%
                    </div>
                    <div class="pt-2 col-8">
                      <strong>💡 {{ $t('institutionEbay.example') }}:</strong>
                      <strong> {{ $t('institutionEbay.price_ebay') }} </strong>= (Fanale: 90 € + 10 € {{ $t('institutionEbay.shipping') }}) * 1.125 = <strong>125 €</strong>
                    </div>
                  </div>
                  <div class="col-12 pt-1 ml-3" style="color: grey !important">
                    *{{ $t('institutionEbay.ebay_comission_1') }}: <strong> {{ $t('institutionEbay.ebay_comission_2') }}</strong>
                  </div>
                </v-alert>
              </v-card>
            </v-flex>

            <v-flex
              xs12
              sm12
              d-flex
              class="pl-3 pr-3"
            >
              <v-card class="pa-3">
                <v-alert
                  :value="true"
                  type="error"
                  border="bottom"
                  outline
                  prominent
                  text
                >
                  {{ $t('institutionEbay.parts_info') }}
                </v-alert>
              </v-card>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div
      v-if="titleDetails"
      class="col-6"
    >
      <div
        class="pa-4"
      >
        <v-toolbar
          flat
          dark
          class=""
        >
          <v-toolbar-title>
            {{ $t('institutionEbay.weight_range') }}
          </v-toolbar-title>
        </v-toolbar>
      </div>

      <div class="d-flex pl-3">
        <v-flex
          xs12
          sm2
          align-start
          justify-start
          class="ml-2"
        >
          <label>{{ $t('leko_employees.shippings.weight') }}</label>
          <v-autocomplete
            v-model="selectedWeightRange"
            :items="weightRanges"
            :label="$t('leko_employees.shippings.weight')"
            persistent-hint
            item-text="label"
            item-value="value"
            solo
            color="red darken-1"
            class="ml-2 mr-2  pt-2"
          />
        </v-flex>
        <v-flex
          xs12
          sm4
          align-start
          justify-start
          class="mt-4"
          d-flex
        >
          <v-btn
            :loading="loadingUpdateWeight"
            :disabled="!selectedWeightRange"
            color="red darken-1"
            class="white--text"
            @click="updateWeightRange"
          >
            <v-icon
              class="mr-2"
            >
              mdi-weight-kilogram
            </v-icon> {{ $t('leko_employees.ebay_shipping_price.update_weight') }}
          </v-btn>
        </v-flex>
      </div>
      <v-flex
        xs12
        sm5
        d-flex
        class="ml-4"
      >
        <div>
          <v-alert
            :value="true"
            type="error"
            border="bottom"
            class="mt-3"
            outline
            prominent
            text
          >
            {{ $t('institutionEbay.weight_info') }}
          </v-alert>
        </div>
      </v-flex>
    </div>
    <div class="vue-mastery ma-3">
      <a
        href="https://youtu.be/UtNAToxBdXY"
        target="_blank"
        rel="sponsored noopener"
        title=""
      >{{ $t('institutionEbay.watch_video') }}</a>
    </div>
    <v-dialog
      v-model="loading"
      style="height: 100vh; width: 100vw;"
    >
      <v-card
        outlined
        color="transparent"
        class="col-12 justify-center pt-10 text-center"
        transparent
        style="height: 90vh; width: 95vw;"
      >
        <v-progress-circular
          color="#D32F2F"
          indeterminate
          size="100"
          style="margin-top: 35vh"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="success"
      max-width="300"
    >
      <v-alert
        v-model="success"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.success') }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="error"
      max-width="300"
    >
      <v-alert
        v-model="error"
        type="error"
        dismissible
        :value="true"
      >
        {{ $t('Error') }}
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import getRequest from '../api/getRequest';
import putRequest from '../api/putRequest';

export default {
  data: () => ({
    searchTitle: null,
    loadingTitle: false,
    title: null,
    titles: [],
    price: 0,
    loadingAssignPrice: false,
    institution: null,
    institutions: [],
    marketplace: 'ebay',
    markets: [
      { title: 'eBay', value: 'ebay' },
      { title: 'Google', value: 'google' },
    ],
    country: 'it',
    countries: [
      { title: 'Italy', code: 'it' },
      { title: 'Germany', code: 'de' },
      { title: 'France', code: 'fr' },
      { title: 'Spain', code: 'es' },
    //   { title: 'United Kingdom', code: 'uk' },
    ],
    loadingInstitution: false,
    loadingAssignInstitutionPrice: false,
    loadingAssignPartPrice: false,
    loading: false,
    code: null,
    category: null,
    success: false,
    error: false,
    displayedInstitutions: [],
    titleDetails: null,
    selectedWeightRange: '',
    weightRanges: [
      { value: null, label: 'Default' },
      { value: '0-2', label: '0-2 kg' },
      { value: '2,1-5', label: '2,1-5 kg' },
      { value: '5,1-10', label: '5,1-10 kg' },
      { value: '10,1-15', label: '10,1-15 kg' },
      { value: '15,1-25', label: '15,1-25 kg' },
      { value: '25,1-50', label: '25,1-50 kg' },
      { value: '50,1-75', label: '50,1-75 kg' },
      { value: '75,1-100', label: '75,1-100 kg' },
      { value: '101', label: '100+ kg' },
      { value: '101,1-150', label: '101,1-150 kg' },
      { value: '150,1-200', label: '150,1-200 kg' },
      { value: '201,1-250', label: '201,1-250 kg' },
      { value: '250,1-300', label: '250,1-300 kg' },
    ],
    loadingUpdateWeight: false,
  }),
  watch: {
    searchTitle(value) {
      if (value) {
        this.loadingTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingTitle = false; });
      } else {
        this.title = null;
        this.titles = [];
      }
    },
    title: {
      handler(newTitle) {
        if (newTitle) {
          this.getTitleDetails(newTitle.partCode);
        }
      },
      deep: true,
    },
  },
  created() {
    this.getInstitutions();
  },
  methods: {
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/').then((response) => {
        this.institutions = response.institutions;
        if (this.$store.getters.role === 'SuperAdmin') {
          this.displayedInstitutions = this.institutions;
        } else {
          this.displayedInstitutions = this.institutions.filter(institution => institution._id === this.$store.getters.institutionId);
          this.institution = this.displayedInstitutions[0];
        }
        this.loadingInstitution = false;
      }).catch((error) => {
        console.log(error);
        this.loadingInstitution = false;
      });
    },
    getTitleDetails(partCode) {
      this.titleDetails = null;
      getRequest('/shipping-reference-cost/', `partCode=${partCode}&institutionId=${this.institution._id || this.institution}`).then((response) => {
        if (response && response.data) {
          this.titleDetails = response.data;
          this.price = response.data.shippingCosts && response.data.shippingCosts.ebay && response.data.shippingCosts.ebay.it ? response.data.shippingCosts.ebay.it : null;
          this.selectedWeightRange = response.data.weightRange;
        } else {
          this.price = null;
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    assignInstitutionPrice() {
      this.loadingAssignInstitutionPrice = true;
      this.loading = true;
      putRequest('/shipping-reference-cost', '', {
        partCode: this.title.partCode,
        cost: this.price,
        institutionId: this.institution._id,
        marketplace: this.marketplace,
        country: this.country,
        weightRange: this.selectedWeightRange,
      })
        .then((response) => {
          if (response) {
            this.success = true;
            this.getTitleDetails(response.data.partCode);
            setTimeout(() => { this.success = false; }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          setTimeout(() => { this.error = false; }, 3000);
        })
        .finally(() => {
          this.loadingAssignInstitutionPrice = false;
          this.loading = false;
        });
    },
    assignPartPrice() {
      this.loadingAssignPartPrice = true;
      this.loading = true;
      putRequest('/shipping-reference-cost/parts', '', {
        partCode: this.title.partCode,
        cost: this.price,
        institutionId: this.institution._id,
        marketplace: this.marketplace,
        country: this.country,
        weightRange: this.selectedWeightRange,
      })
        .then((response) => {
          if (response) {
            this.success = true;
            this.getTitleDetails(this.title.partCode);
            setTimeout(() => { this.success = false; }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          setTimeout(() => { this.error = false; }, 3000);
        })
        .finally(() => {
          this.loadingAssignPartPrice = false;
          this.loading = false;
        });
    },
    updateWeightRange() {
      this.loadingUpdateWeight = true;
      this.loading = true;
      putRequest('/shipping-reference-cost/weight-range', '', {
        partCode: this.title.partCode,
        institutionId: this.institution._id,
        weightRange: this.selectedWeightRange,
      })
        .then((response) => {
          if (response) {
            this.success = true;
            this.getTitleDetails(this.title.partCode);
            setTimeout(() => { this.success = false; }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          setTimeout(() => { this.error = false; }, 3000);
        })
        .finally(() => {
          this.loadingUpdateWeight = false;
          this.loading = false;
        });
    },
  },
};
</script>
  <style scoped>
  .text-start{
    text-align: start !important;
  }
  .vue-mastery {
  background-color: #e7ecf3;
  padding: 1em 1.25em;
  border-radius: 2px;
  color: #486491;
  position: relative;
  margin-top: 1em;
}
.vue-mastery a {
  color: #486491 !important;
  position: relative;
  padding-left: 36px;
}
.vue-mastery a:before {
  content: '';
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  top: -5px;
  left: -4px;
  border-radius: 50%;
  background-color: #73abfe;
}
.vue-mastery a:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 5px;
  left: 8px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #fff;
}
.text-style{
        font-size: 16px;
    }
    .display{
      display: flex;
    }
@media (max-width:625px){
    .text-style{
        font-size: 12px;
    }
    .display{
      display: block;
    }
}
  </style>
