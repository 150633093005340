<template>
  <div>
    <v-dialog
      v-model="ebayDetailsModal"
      persistent
    >
      <v-toolbar
        flat
        dark
        style="background-color: #D32F2F"
      >
        <v-toolbar-title>{{ $t('institutions.table.ebay_details') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          outline
          icon
          small
          color="white"
          @click="closeEbayDetailsModal"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="pa-3">
        <v-progress-linear
          v-if="loading"
          color="#D32F2F"
          indeterminate
          rounded
          height="6"
        />
        <div />
        <div class="col-12 d-flex">
          <div class="col-6 pa-3">
            <div>
              <v-text-field
                v-model="item.token"
                :label="$t('institutions.form.token')"
                color="red darken-1"
                prepend-icon="security"
              />
            </div>
            <div>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="formattedTokenExpirationDate"
                    :label="$t('institutions.form.tokenExpirationDate')"
                    color="black"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>

                <v-card class="d-md-flex">
                  <!-- Date Picker -->
                  <v-date-picker
                    v-model="date"
                    color="red darken-3"
                    @input="updateTokenExpirationDate"
                  />

                  <!-- Time Picker -->
                  <v-time-picker
                    v-model="time"
                    color="red darken-3"
                    full-width
                    format="24hr"
                    @input="updateTokenExpirationDate"
                  />
                  <!-- <v-card-actions>
                    <v-btn text @click="saveDateTime">{{ $t('institutions.form.save') }}</v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-menu>
            </div>
            <!-- <div>
          <v-text-field
            v-model="item.shippingId"
            :label="$t('institutions.form.shippingId')"
            color="red darken-1"
            prepend-icon="shop"
          />
        </div>
        <div>
          <v-text-field
            v-model="item.paymentId"
            :label="$t('institutions.form.paymentId')"
            color="red darken-1"
            prepend-icon="payment"
          />
        </div>
        <div>
          <v-text-field
            v-model="item.returnId"
            :label="$t('institutions.form.returnId')"
            color="red darken-1"
            prepend-icon="refresh"
          />
        </div> -->
            <div>
              <v-text-field
                v-model="item.userId"
                disabled
                :label="$t('institutions.form.userId')"
                color="red darken-1"
                prepend-icon="person"
              />
            </div>
            <div>
              <v-text-field
                v-model="item.storeUrl"
                disabled
                :label="$t('institutions.form.storeUrl')"
                color="red darken-1"
                prepend-icon="link"
              />
            </div>
            <div>
              <v-text-field
                v-model="item.eBayLocation"
                :label="$t('institutions.form.location')"
                color="red darken-1"
                prepend-icon="location_city"
              />
            </div>
            <v-alert
              :value="true"
              type="error"
              border="bottom"
              outline
              prominent
              text
            >
              {{ $t('institutions.form.ebay_condition') }}
            </v-alert>
          </div>
          <div class="col-6">
            <v-tabs v-model="selectedCountry">
              <v-tab
                v-for="country in ['it', 'de']"
                :key="country"
              >
                {{ country.toUpperCase() }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedCountry">
              <v-tab-item
                v-for="country in Object.keys(item.shippingId)"
                :key="country"
              >
                <v-container>
                  <!-- Shipping ID fields -->
                  <div style="max-height: 300px; overflow-y: auto">
                    <div
                      v-for="(value, range) in item.shippingId[country]"
                      :key="'shipping-' + range"
                      class="d-flex"
                    >
                      <v-text-field
                        v-model="item.shippingId[country][range]"
                        :label="range === 'default' ? 'Default Shipping ID' : `Shipping ID (${range} kg)`"
                        color="red darken-1"
                        prepend-icon="shop"
                      />
                      <v-btn
                        style="max-width: 20px !important"
                        :color="item.shippingId[country][range] ? 'success' : ''"
                        @click="verifyShippingId(item.token,item.shippingId[country][range],item.paymentId[country],item.returnId[country],country)"
                      >
                        Verify
                      </v-btn>
                    </div>
                  </div>
                  <!-- Payment ID field -->
                  <v-text-field
                    v-model="item.paymentId[country]"
                    :label="`Payment ID`"
                    color="red darken-1"
                    prepend-icon="payment"
                  />

                  <!-- Return ID field -->
                  <v-text-field
                    v-model="item.returnId[country]"
                    :label="`Return ID`"
                    color="red darken-1"
                    prepend-icon="refresh"
                  />
                </v-container>
              </v-tab-item>
            </v-tabs-items>
            <!-- <v-tabs-items v-model="selectedCountry">
              <v-tab-item
                v-for="country in ['it', 'de']"
                :key="country"
              >
                <v-container>
                  <v-text-field
                    v-model="item.shippingId[country]"
                    :label="$t('institutions.form.shippingId')"
                    color="red darken-1"
                    prepend-icon="shop"
                  />
                  <v-text-field
                    v-model="item.paymentId[country]"
                    :label="$t('institutions.form.paymentId')"
                    color="red darken-1"
                    prepend-icon="payment"
                  />
                  <v-text-field
                    v-model="item.returnId[country]"
                    :label="$t('institutions.form.returnId')"
                    color="red darken-1"
                    prepend-icon="refresh"
                  />
                </v-container>
              </v-tab-item>
            </v-tabs-items> -->
          </div>
        </div>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="gray darken-1"
            @click.native="closeEbayDetailsModal"
          >
            {{ $t('institutions.form.close') }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            :disabled="checkEbayDetails"
            @click="saveEbayDetails"
          >
            {{ $t('institutions.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="ebayStoreCategoriesModal"
      persistent
      width="650"
    >
      <v-toolbar
        flat
        dark
        style="background-color: #D32F2F"
      >
        <v-toolbar-title>{{ $t('institutions.table.ebay_store_categories_title') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          outline
          icon
          small
          color="white"
          @click="closeEbayCategoriesModal"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="pa-3">
        <v-progress-linear
          v-if="loadingSave"
          color="#D32F2F"
          indeterminate
          rounded
          height="6"
        />
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-tabs>
            <v-tab
              v-for="lang in languages"
              :key="lang.code"
            >
              {{ lang.label }}
            </v-tab>

            <v-tab-item
              v-for="lang in languages"
              :key="lang.code"
            >
              <v-card class="pa-3 pb-5">
                <div class="col-12 d-flex">
                  <v-text-field
                    v-model="categoryNames[lang.code]"
                    :label="$t('institutions.form.ebay_category_name')"
                    color="red darken-1"
                    class="mr-4"
                  />
                  <v-text-field
                    v-model="categoryNumbers[lang.code]"
                    :label="$t('institutions.form.ebay_category_number')"
                    color="red darken-1"
                    @keypress="isNumber($event)"
                  />
                  <v-btn
                    small
                    color="red"
                    class="white--text pa-0 mt-3 mr-0 ml-3"
                    @click="addCategory(lang.code)"
                  >
                    {{ $t('institutions.form.add') }}
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <v-divider
                  class="mt-3 mb-3"
                  horizontal
                />
                <div
                  v-if="categories.find(c => c.language === lang.code).categoryList.length > 0"
                  class="col-12 d-flex"
                >
                  <strong>{{ $t('institutions.form.ebay_category_name') }}</strong>
                  <strong>{{ $t('institutions.form.ebay_category_number') }}</strong>
                </div>
                <div
                  v-for="(category, index) in categories.find(c => c.language === lang.code).categoryList"
                  :key="index"
                  class="mt-2 col-12 d-flex"
                >
                  <v-text-field
                    v-model="category.title"
                    :label="$t('institutions.form.ebay_category_name')"
                    color="red darken-1"
                    class="mr-2"
                    solo
                    :rules="rules.required"
                  />
                  <v-text-field
                    v-model="category.ebayCode"
                    :label="$t('institutions.form.ebay_category_number')"
                    color="red darken-1"
                    solo
                    :rules="rules.required"
                    @keypress="isNumber($event)"
                  />
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="gray darken-1"
            @click.native="closeEbayCategoriesModal"
          >
            {{ $t('institutions.form.close') }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            @click="saveCategories()"
          >
            {{ $t('institutions.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ebayErrorDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in normalizedEbayResponse"
          :key="index"
          :value="true"
          type="error"
        >
          {{ item.ShortMessage }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="ebayErrorDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ebayErrorDetailsDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-if="ebayResponse && ebayResponse.it"
          :value="true"
          type="error"
        >
          <strong>IT: </strong> {{ ebayResponse.it }}
        </v-alert>
        <v-alert
          v-if="ebayResponse && ebayResponse.de"
          :value="true"
          type="error"
        >
          <strong>DE: </strong> {{ ebayResponse.de }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="closeEbayDetailsErrprModal()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="alert"
      max-width="500"
    >
      <v-alert
        v-model="alert"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.verification_success') }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="success"
      max-width="300"
    >
      <v-alert
        v-model="success"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.success') }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="ebayPreferencesModal"
      persistent
      width="500"
    >
      <v-toolbar
        flat
        dark
        style="background-color: #D32F2F"
      >
        <v-toolbar-title>Ebay {{ $t('institutions.table.preferences') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          outline
          icon
          small
          color="white"
          @click="closeEbayPreferences"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="pa-3">
        <div class="col-12 d-flex align-center mb-2 font-weight-bold">
          <div class="col-2" />
          <div class="col-5">
            {{ $t('institutions.form.export') }}
          </div>
          <div class="col-5">
            {{ $t('institutions.form.duplicate') }}
          </div>
        </div>
        <v-divider />
        <div
          v-for="(exportValue, locale) in exportEbayLekoInternational"
          :key="locale"
          class="col-12 d-flex align-center mb-2"
        >
          <!-- Locale/Flag Label -->
          <div class="col-2">
            <img :src="getImg(locale)">
          </div>

          <!-- Export Switch -->
          <div class="col-5">
            <v-switch
              v-model="exportEbayLekoInternational[locale]"
              :disabled="locale == 'it'"
              color="red darken-1"
            />
          </div>

          <!-- Duplicate Switch -->
          <div class="col-5">
            <v-switch
              v-model="duplicateEbayListings[locale]"
              color="red darken-1"
            />
          </div>
        </div>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="saveEbayPreferences()"
          >
            {{ $t('institutions.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="warning"
      max-width="500"
    >
      <v-alert
        v-model="warning"
        type="warning"
        dismissible
        :value="true"
      >
        {{ requiredFields }} are required
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="verifyShippingErrorModal"
      max-width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-model="verifyShippingErrorModal"
          type="error"
          dismissible
          :value="true"
        >
          {{ verificationError }}
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {
  },
  data: () => ({
    institution_id: null,
    ebayDetailsModal: false,
    item: {
      token: '',
      tokenExpirationDate: '',
      shippingId: {
        it: {
          default: '', '0-2': '', '2,1-5': '', '5,1-10': '', '10,1-15': '', '15,1-25': '', '25,1-50': '', '50,1-75': '', '75,1-100': '', '100,1': '', '101,1-150': '', '150,1-200': '', '201,1-250': '', '250,1-300': '',
        },
        de: {
          default: '', '0-2': '', '2,1-5': '', '5,1-10': '', '10,1-15': '', '15,1-25': '', '25,1-50': '', '50,1-75': '', '75,1-100': '', '100,1': '', '101,1-150': '', '150,1-200': '', '201,1-250': '', '250,1-300': '',
        },
      },
      paymentId: { it: '', de: '' },
      returnId: { it: '', de: '' },
      userId: '',
      storeUrl: '',
      eBayLocation: '',
    },
    selectedCountry: 0,
    dateMenu: false,
    date: null,
    time: null,
    ebayResponse: null,
    ebayErrorDialog: false,
    loading: false,
    alert: false,
    inst: null,
    ebayStoreCategoriesModal: false,
    loadingSave: false,
    institutionDetails: null,
    languages: [
      { code: 'it', label: 'IT' },
      { code: 'en', label: 'EN' },
      { code: 'de', label: 'DE' },
      { code: 'fr', label: 'FR' },
    ],
    categoryNames: {
      it: '',
      en: '',
      de: '',
      fr: '',
    },
    categoryNumbers: {
      it: '',
      en: '',
      de: '',
      fr: '',
    },
    // categories: {
    //   it: [],
    //   en: [],
    //   de: [],
    //   fr: [],
    // },
    categoryInputs: {
      it: { title: '', ebayCode: '' },
      en: { title: '', ebayCode: '' },
      de: { title: '', ebayCode: '' },
      fr: { title: '', ebayCode: '' },
    },
    defaultCategories: [
      {
        language: 'it',
        categoryList: [
          { title: 'Lamierati esterni', ebayCode: '' },
          { title: 'Lamierati interni', ebayCode: '' },
          { title: 'Fanaleria', ebayCode: '' },
          { title: 'Selleria', ebayCode: '' },
          { title: 'Motore e cambio con supporti', ebayCode: '' },
          { title: 'Paraurti anteriore', ebayCode: '' },
          { title: 'Paraurti posteriore', ebayCode: '' },
          { title: 'Sterzo', ebayCode: '' },
          { title: 'Sospensioni', ebayCode: '' },
          { title: 'Cristalli', ebayCode: '' },
          { title: 'Alimentazione e scarico', ebayCode: '' },
          { title: 'Cambio e trasmissioni', ebayCode: '' },
          { title: 'Impianto frenante', ebayCode: '' },
          { title: 'Rivestimenti interni e parti interne', ebayCode: '' },
          { title: 'Dispositivi di sicurezza', ebayCode: '' },
          { title: 'Climatizzazione', ebayCode: '' },
          // { title: 'Altro', ebayCode: '' },
        ],
      },
      {
        language: 'en',
        categoryList: [
          // { title: 'Other', ebayCode: '' },
        ],
      },
      {
        language: 'de',
        categoryList: [
          // { title: 'Andere', ebayCode: '' },
        ],
      },
      {
        language: 'fr',
        categoryList: [
          // { title: 'Autre', ebayCode: '' },
        ],
      },
    ],
    categories: [
      {
        language: 'it',
        categoryList: [],
      },
      { language: 'en', categoryList: [] },
      { language: 'de', categoryList: [] },
      { language: 'fr', categoryList: [] },
    ],
    // baseUrl: 'http://localhost:3000',
    baseUrl: 'https://api.lekotech.com',
    rules: {
      required: [v => !!v || 'Please fill this field'],
    },
    valid: false,
    success: false,
    ebayPreferencesModal: false,
    tab: 'export',
    preferences: [
      { title: 'export' },
      { title: 'duplicates' },
    ],
    exportEbayLekoInternational: {
      it: true,
      de: false,
      fr: false,
    },
    duplicateEbayListings: {
      it: false,
      de: false,
      fr: false,
    },
    ebayErrorDetailsDialog: false,
    requiredFields: '',
    warning: false,
    verifyShippingErrorModal: false,
    verificationError: '',
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    checkEbayDetails() {
      const {
        token, tokenExpirationDate, paymentId, returnId,
      } = this.item;

      return !token ||
         !tokenExpirationDate ||
         !paymentId.it ||
         !returnId.it;
    },
    formattedTokenExpirationDate() {
      if (!this.item.tokenExpirationDate) return '';
      const date = new Date(this.item.tokenExpirationDate);
      return date.toString().slice(0, 28);
    },
    normalizedEbayResponse() {
      if (this.ebayResponse && typeof this.ebayResponse === 'object' && !Array.isArray(this.ebayResponse)) {
        return [this.ebayResponse];
      }
      return this.ebayResponse || [];
    },
  },
  watch: {
    valid(value) {
      this.$emit('valid', value);
    },
  },

  methods: {
    openEbayDetailsModal(institution) {
      this.institution_id = institution._id;
      this.inst = institution;
      const shippingIt = institution.eBayDetails && institution.eBayDetails.shippingId && institution.eBayDetails.shippingId.it
        ? institution.eBayDetails.shippingId.it : {
          default: '',
          '0-2': '',
          '2,1-5': '',
          '5,1-10': '',
          '10,1-15': '',
          '15,1-25': '',
          '25,1-50': '',
          '50,1-75': '',
          '75,1-100': '',
          '100,1': '',
          '101,1-150': '',
          '150,1-200': '',
          '201,1-250': '',
          '250,1-300': '',
        };
      const shippingDe = institution.eBayDetails && institution.eBayDetails.shippingId && institution.eBayDetails.shippingId.it
        ? institution.eBayDetails.shippingId.de : {
          default: '',
          '0-2': '',
          '2,1-5': '',
          '5,1-10': '',
          '10,1-15': '',
          '15,1-25': '',
          '25,1-50': '',
          '50,1-75': '',
          '75,1-100': '',
          '100,1': '',
          '101,1-150': '',
          '150,1-200': '',
          '201,1-250': '',
          '250,1-300': '',
        };
      this.item = {
        token: institution.eBayDetails && institution.eBayDetails.token ? institution.eBayDetails.token : '',
        tokenExpirationDate: institution.eBayDetails && institution.eBayDetails.tokenExpirationDate ? institution.eBayDetails.tokenExpirationDate : '',
        // shippingId: {
        //   it: institution.eBayDetails && institution.eBayDetails.shippingId && institution.eBayDetails.shippingId.it || '',
        //   de: institution.eBayDetails && institution.eBayDetails.shippingId && institution.eBayDetails.shippingId.de || '',
        // },
        shippingId: {
          it: {
            default: shippingIt ? shippingIt.default : null,
            '0-2': shippingIt ? shippingIt[2] : null,
            '2,1-5': shippingIt ? shippingIt[5] : null,
            '5,1-10': shippingIt ? shippingIt[10] : null,
            '10,1-15': shippingIt ? shippingIt[15] : null,
            '15,1-25': shippingIt ? shippingIt[25] : null,
            '25,1-50': shippingIt ? shippingIt[50] : null,
            '50,1-75': shippingIt ? shippingIt[75] : null,
            '75,1-100': shippingIt ? shippingIt[100] : null,
            '100,1': shippingIt ? shippingIt[101] : null,
            '101,1-150': shippingIt ? shippingIt[150] : null,
            '150,1-200': shippingIt ? shippingIt[200] : null,
            '201,1-250': shippingIt ? shippingIt[250] : null,
            '250,1-300': shippingIt ? shippingIt[300] : null,
          },
          de: {
            default: shippingDe ? shippingDe.default : null,
            '0-2': shippingDe ? shippingDe[2] : null,
            '2,1-5': shippingDe ? shippingDe[5] : null,
            '5,1-10': shippingDe ? shippingDe[10] : null,
            '10,1-15': shippingDe ? shippingDe[15] : null,
            '15,1-25': shippingDe ? shippingDe[25] : null,
            '25,1-50': shippingDe ? shippingDe[50] : null,
            '50,1-75': shippingDe ? shippingDe[75] : null,
            '75,1-100': shippingIt ? shippingIt[100] : null,
            '100,1': shippingDe ? shippingDe[101] : null,
            '101,1-150': shippingDe ? shippingDe[150] : null,
            '150,1-200': shippingDe ? shippingDe[200] : null,
            '201,1-250': shippingDe ? shippingDe[250] : null,
            '250,1-300': shippingDe ? shippingDe[300] : null,
          },
        },
        paymentId: {
          it: institution.eBayDetails && institution.eBayDetails.paymentId && institution.eBayDetails.paymentId.it || '',
          de: institution.eBayDetails && institution.eBayDetails.paymentId && institution.eBayDetails.paymentId.de || '',
        },
        returnId: {
          it: institution.eBayDetails && institution.eBayDetails.returnId && institution.eBayDetails.returnId.it || '',
          de: institution.eBayDetails && institution.eBayDetails.returnId && institution.eBayDetails.returnId.de || '',
        },
        userId: institution.eBayDetails.userId || null,
        storeUrl: institution.eBayDetails.storeUrl || null,
        eBayLocation: institution.eBayDetails.eBayLocation || null,
      };
      this.ebayDetailsModal = true;
    },
    verifyShippingId(accessToken, shippingId, paymentId, returnId, site) {
      const missingFields = [];
      if (!accessToken) missingFields.push('Token');
      if (!shippingId) missingFields.push('Shipping ID');
      if (!paymentId) missingFields.push('Payment ID');
      if (!returnId) missingFields.push('Return ID');

      if (missingFields.length > 0) {
        this.requiredFields = missingFields.join(', ');
        this.warning = true;
        return;
      }
      this.loading = true;

      axios.post(
        `${this.baseUrl}/ebay/verify-add-item`,
        {
          accessToken,
          shippingId,
          paymentId,
          returnId,
          site,
        }, {
          headers: { authorization: localStorage.token },
        },
      )
        .then((response) => {
          if (response && response.data && response.data.errorMsg) {
            this.verificationError = response.data.errorMsg;
            this.verifyShippingErrorModal = true;
          } else {
            this.alert = true;
            setTimeout(() => { this.alert = false; }, 3000);
            this.$emit('fetchInstitutionDetails');
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error verify items to eBay:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveEbayDetails() {
      const {
        shippingId, paymentId, returnId, ...rest
      } = this.item;
      const transformedShippingIdIt = {};
      const transformedShippingIdDE = {};
      // Map current shippingId labels to the new format
      const weightMapping = {
        '0-2': '2',
        '2,1-5': '5',
        '5,1-10': '10',
        '10,1-15': '15',
        '15,1-25': '25',
        '25,1-50': '50',
        '50,1-75': '75',
        '75,1-100': '100',
        '100,1': '101',
        '101,1-150': '150',
        '150,1-200': '200',
        '201,1-250': '250',
        '250,1-300': '300',
      };
      Object.keys(shippingId.it).forEach((label) => {
        if (shippingId.it[label]) {
          const newKey = weightMapping[label] || label;
          transformedShippingIdIt[newKey] = shippingId.it[label];
        }
      });
      const filteredData = {
        ...rest,
        shippingId: { it: transformedShippingIdIt },
        paymentId: { it: paymentId.it },
        returnId: { it: returnId.it },
      };
      const isGermanyFilled =
      Object.values(shippingId.de).some(value => value) ||
      !!paymentId.de ||
      !!returnId.de;
      if (isGermanyFilled) {
        Object.keys(shippingId.de).forEach((label) => {
          if (shippingId.it[label]) {
            const newKey = weightMapping[label] || label;
            transformedShippingIdDE[newKey] = shippingId.de[label];
          }
        });
        // Add 'de' fields if fully filled
        filteredData.shippingId.de = transformedShippingIdDE;
        filteredData.paymentId.de = paymentId.de;
        filteredData.returnId.de = returnId.de;
      }
      this.loading = true;
      axios.put(
        `${this.baseUrl}/institution/edit-ebay-details/${this.institution_id}`,
        { eBayDetails: filteredData }, {
          headers: { authorization: localStorage.token },
        },
      )
        .then((response) => {
          if (response && response.data && response.data.errorMsg) {
            this.ebayResponse = response.data;
            this.ebayErrorDetailsDialog = true;
          } else {
            this.alert = true;
            this.ebayDetailsModal = false;
            // this.item = response.data && response.data.eBayDetails ? response.data.eBayDetails : {
            //   token: '',
            //   tokenExpirationDate: '',
            //   shippingId: { it: '', de: '' },
            //   paymentId: { it: '', de: '' },
            //   returnId: { it: '', de: '' },
            //   userId: '',
            //   storeUrl: '',
            // };
            setTimeout(() => { this.alert = false; }, 3000);
            this.$emit('fetchInstitutionDetails');
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error adding items to eBay:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeEbayDetailsErrprModal() {
      this.ebayErrorDetailsDialog = false;
      this.ebayResponse = null;
    },
    closeEbayDetailsModal() {
      this.ebayDetailsModal = false;
      this.$emit('fetchInstitutionDetails');
    },
    updateTokenExpirationDate() {
      if (this.date && this.time) {
        const [hours, minutes] = this.time.split(':');
        const fullDate = new Date(this.date);
        fullDate.setHours(hours, minutes, 0, 0);
        this.item.tokenExpirationDate = fullDate.toISOString();
      }
    },
    saveDateTime() {
      this.dateMenu = false;
    },
    closeDateTimeMenu() {
      this.dateMenu = false;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard:', text);
      }).catch((err) => {
        console.error('Failed to copy text:', err);
      });
    },
    openEbayStoreCategoriesModal(institution) {
      this.ebayStoreCategoriesModal = true;
      this.institutionDetails = institution;
      this.institution_id = institution._id;
      axios.get(`${this.baseUrl}/ebay-local-categories/?institution_id=${institution._id}`, {
        headers: { authorization: localStorage.token },
      }).then((res) => {
        if (res) {
          const dbCategories = res.data.ebayCategories && res.data.ebayCategories.categories ? res.data.ebayCategories.categories : null;
          if (dbCategories == null) {
            this.categories = this.defaultCategories;
          } else {
            this.categories = dbCategories;
          }
        }
      }).catch((err) => {
        // console.log('ebay error', err.response.data);
      })
        .finally(() => {
        // nth
        });
    },
    closeEbayCategoriesModal() {
      this.ebayStoreCategoriesModal = false;
    },
    saveCategories() {
      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        axios.put(
          `${this.baseUrl}/ebay-local-categories/?institution_id=${this.institution_id}`,
          { categories: this.categories }, {
            headers: { authorization: localStorage.token },
          },
        )
          .then((response) => {
            this.loadingSave = false;
            this.success = true;
            this.$emit('fetchInstitutionDetails');
            setTimeout(() => { this.success = false; }, 3000);
          })
          .catch((error) => {
            this.loadingSave = false;
            // console.error('Error :', error);
          })
          .finally(() => {
            this.loadingSave = false;
          });
      } else {
        // console.log('categories', this.categories);
      }
    },
    addCategory(lang) {
      if (this.categoryNames[lang]) {
        const categoryForLang = this.categories.find(cat => cat.language === lang);
        if (categoryForLang) {
          categoryForLang.categoryList.push({
            title: this.categoryNames[lang],
            ebayCode: this.categoryNumbers[lang],
          });
        }
        this.categoryNames[lang] = '';
        this.categoryNumbers[lang] = '';
      } else {
      // alert("Please fill both fields before adding.");
      }
    },
    removeCategory(lang, index) {
      this.categories[lang].splice(index, 1);
    },
    openEbayPreferencesModal(institution) {
      this.institution_id = institution._id;
      if (institution && institution.exportEbayLekoInternational && Object.keys(institution.exportEbayLekoInternational).length > 0) {
        this.exportEbayLekoInternational = {
          it: institution.export_ebay_leko,
          ...institution.exportEbayLekoInternational,

        };
      } else {
        this.exportEbayLekoInternational = {
          it: institution.export_ebay_leko,
          de: false,
          fr: false,
        };
      }
      if (institution && institution.duplicateEbayListings && Object.keys(institution.duplicateEbayListings).length > 0) {
        this.duplicateEbayListings = institution.duplicateEbayListings;
      } else {
        this.duplicateEbayListings = {
          it: false,
          de: false,
          fr: false,
        };
      }

      this.ebayPreferencesModal = true;
    },
    saveEbayPreferences() {
      this.loading = true;
      axios.put(
        `${this.baseUrl}/institution/edit-ebay-leko-preferences/${this.institution_id}`,
        {
          exportEbayLekoInternational: this.exportEbayLekoInternational,
          duplicateEbayListings: this.duplicateEbayListings,
        }, {
          headers: { authorization: localStorage.token },
        },
      )
        .then((response) => {
          if (response && response.data && response.data.errorMsg) {
            this.ebayResponse = response.data.errorMsg;
            this.ebayErrorDialog = true;
          } else {
            this.alert = true;
            this.ebayPreferencesModal = false;
            setTimeout(() => { this.alert = false; }, 3000);
            this.$emit('fetchInstitutionDetails');
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error saving evay preferences:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeEbayPreferences() {
      this.ebayPreferencesModal = false;
    },
    getImg(item) {
      switch (item) {
        case 'it':
          return 'https://portal.lekotech.com/img/icons/ebayIcons/italy.svg';
        case 'de':
          return 'https://portal.lekotech.com/img/icons/ebayIcons/germany.svg';
        case 'es':
          return 'https://portal.lekotech.com/img/icons/ebayIcons/spain.svg';
        case 'fr':
          return 'https://portal.lekotech.com/img/icons/ebayIcons/france.svg';
        case 'uk':
          return 'https://portal.lekotech.com/img/icons/ebayIcons/united-kingdom.svg';
        default:
          return '/';
      }
    },
    isNumber(evt) {
      // Only allow numeric values (digits 0-9)
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      }
    },
  },
};
</script>
